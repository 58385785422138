import React, { useState } from 'react';
import HomePage from 'components/home-page';
import isUserAgentCrawler from 'utils/crawler-agent';
import { getObjectFromRequestCookie } from 'utils/cookies';
import configJSON from 'config.json';
import { type CountryCode } from 'types/countries';
import { getFeaturesOverrideFromRequest } from 'utils/features';
import { HIDE_NOTIFICATION_BANNER_COOKIE_NAME, getLastSearchLocationTag } from 'constants/cookies';
import { checkLocationHasAreaPageWithId, getLocationSlugFromId } from 'data/search-predictions';
import { getNamedContents } from 'data/named-content';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';
import {
  extractUrlData,
  grabSlug,
  SanitizeProvinceOrStateCode,
} from 'components/dynamic-page/new-area-listings-page/get-server-side-props';
import { fetchHomePageListings, fetchHomePageListingsWithBoundary, ListingsQueryType } from 'components/home-page/fetcher';
import { getThemeOverrideFromRequest, themeOrDefault } from 'utils/themes';
import { AreaPageListing } from '@zoocasa/go-search';
import { AreaPageListingCardData } from 'components/dynamic-page/new-area-listings-page/area_page_listing_card_data';
import { ListingCardData } from 'components/listing-card';
import { AVAILABLE_STATUS } from 'contexts/preferences/listing-params/types';
import { defaultHomeType } from 'constants/listing-components';
import { getNSEWWithBoundaryString } from 'utils/boundary';
import { isLastSearchLocationValid } from 'utils/validate-location';
import { USER_AGENT_HEADER_NAME } from 'constants/headers';
import { getUserLocationFromRequest } from 'utils/user-location';
import { LastSearchLocation } from 'contexts';
import MiddlewareManager from 'utils/fetchWithRetry';
import { createTenantHeaderMiddleware } from 'utils/tenant-injector-middleware';

import type { GetServerSidePropsContext } from 'next';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';

export interface INamedContents {
  featuredHomesMlsNums: string[];
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=43200, stale-while-revalidate=60'
  );
  const { req: request, res } = context;
  const themeName = themeOrDefault(getThemeOverrideFromRequest(request));
  //  Create a global middleware to automatically append the Tenant information into fetch requests to go-search
  const tenant = themeName;
  const tenantHeaderMiddleware = createTenantHeaderMiddleware(tenant);
  MiddlewareManager.setGlobalMiddleware(tenantHeaderMiddleware);

  const isCrawler = isUserAgentCrawler(request.headers[USER_AGENT_HEADER_NAME]);
  const { featuredHomesMlsNums } = await getHomeConfig();
  const notificationBannerCookie = getObjectFromRequestCookie<boolean>(HIDE_NOTIFICATION_BANNER_COOKIE_NAME, request);
  const isNotificationBannerHidden = !configJSON.features.useNotificationBanner || !!notificationBannerCookie;
  // Get homepage listings server-side
  const userLocation = await getUserLocationFromRequest(request, res);
  let parsedUserLocation = userLocation;
  // If user is not in country of SiteLocation, set defaults
  if (themeName === 'exprealtyCA' && userLocation.countryCode !== 'CA') {
    parsedUserLocation = defaultCACityPayload;
  } else if (themeName === 'exprealtyUS' && userLocation.countryCode !== 'US') {
    parsedUserLocation = defaultUSCityPayload;
  }

  const lastSearchLocation = getObjectFromRequestCookie<LastSearchLocation>(getLastSearchLocationTag(themeName), request);

  const featureOverrides = getFeaturesOverrideFromRequest(request);
  const features = { ...configJSON.features, ...featureOverrides };

  const extractLocation = (locationName: string) => {
    const parts = locationName.split(',');
    return parts.slice(-2).join(',').trim();
  };
  const locationName = extractLocation(lastSearchLocation?.description || parsedUserLocation.name);

  let availableHomeListingsData;
  let availableCount;
  let isExpandedArea;

  if (isLastSearchLocationValid(lastSearchLocation) && !checkLocationHasAreaPageWithId(lastSearchLocation.id)) { 
    // If last search doesn't have an area page
    const { north, south, east, west } = getNSEWWithBoundaryString(lastSearchLocation.boundary);
    const availableRes = await fetchHomePageListingsWithBoundary({ north, south, east, west }, themeName, false, features.useLegacySearchFilter || false);
    const { totalCount, listings } = availableRes;
    availableCount = totalCount;
    availableHomeListingsData = listings.map((listing: AreaPageListing) => AreaPageListing.toJSON(listing));
    isExpandedArea = true;
  } else {
    const { params, routeMatchObject, slugFallback } = extractUrlData(context);
    const { filter } = params;
    const provinceOrState: string = SanitizeProvinceOrStateCode(routeMatchObject.provinceCode);
    const country = routeMatchObject.country?.toUpperCase() as CountryCode || countryCodeFromProvinceOrState(provinceOrState);
    const parsedSlug = getLocationSlugFromId(lastSearchLocation?.id || '') || getLocationSlugFromDescription(lastSearchLocation?.description) || parsedUserLocation.slug;
    const areaPageSlug = grabSlug(parsedSlug, slugFallback);
    const queryCountry = countryCodeFromProvinceOrState(parsedSlug.slice(-2));

    const availableListingsQuery: ListingsQueryType = {
      country: queryCountry && !isCrawler ? queryCountry : country,
      slug: parsedSlug && !isCrawler ? parsedSlug : areaPageSlug,
      filter: {
        ...filter,
        homeType: defaultHomeType,
        status: AVAILABLE_STATUS,
        hasImage: true,
      },
      pageSize: 15,
      useLegacySearchFilter: features.useLegacySearchFilter || false,
      useUsListings: features.useUsListings || false,
    };
    const availableRes = await fetchHomePageListings(availableListingsQuery);
    const { TotalCount, data: availableListings } = availableRes.areaPageListingResponse;
    const { isExpandedArea: isExpanded } = availableRes;

    availableCount = TotalCount;
    availableHomeListingsData = availableListings.map((listing: AreaPageListing) => AreaPageListing.toJSON(listing));
    isExpandedArea = isExpanded;
  }
  return {
    props: {
      isCrawler,
      featuredHomesMlsNums,
      isNotificationBannerHidden,
      availableHomeListingsData,
      availableHomeListingsMeta: availableCount,
      availableHomeListingsLocation: locationName,
      isExpandedArea,
    }};
}

interface Props {
  isCrawler: boolean;
  featuredHomesMlsNums: INamedContents['featuredHomesMlsNums'];
  isNotificationBannerHidden: boolean;
  availableHomeListingsData: AreaPageListing[];
  availableHomeListingsMeta: number;
  availableHomeListingsLocation: string;
  isExpandedArea: boolean;
}

export const areaPageListingToListingCardData = (listing: AreaPageListing) => (new AreaPageListingCardData(listing));

export default function Home({ isCrawler, featuredHomesMlsNums, isNotificationBannerHidden, availableHomeListingsData, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea = false }: Props) {

  const availableListings = availableHomeListingsData.map((listing: AreaPageListing) => AreaPageListing.fromJSON(listing));
  const [availableListingsData] = useState<ListingCardData[]>(() => {
    const initialState = availableListings.map((listing: AreaPageListing) => areaPageListingToListingCardData(listing));
    return initialState;
  });

  return <HomePage
    isCrawler={isCrawler}
    featuredHomesMlsNums={featuredHomesMlsNums}
    isNotificationBannerHidden={isNotificationBannerHidden}
    availableHomeListings={availableListingsData}
    availableHomeListingsMeta={availableHomeListingsMeta}
    availableHomeListingsLocation={availableHomeListingsLocation}
    isExpandedArea={isExpandedArea}
  />;
}

async function getHomeConfig() {
  const namedContents = await getNamedContents({ filter: { key: 'config-home' }});
  return namedContents[0]?.content || { featuredHomesMlsNums: []};
}

export function getLocationSlugFromDescription(description?: string) {
  if (description) {
    return description.toLowerCase().replaceAll(',', '').replaceAll(' ', '-');
  }
  return null;
}