import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import { blurIds } from 'constants/test-constants';
import Button from 'components/control/button';
import styles from './style.module.scss';

interface Props {
  isLoginRequired: boolean;
  isTermsRequired?: boolean;
  isSmallCard?: boolean;
  isCrea: boolean;
}

export default function ListingCardNewOverlay({ isLoginRequired, isTermsRequired, isSmallCard = false, isCrea }: Props) {
  if (isLoginRequired && !isCrea) {
    return (
      <div className={buildClassName(styles.component, isSmallCard && styles['component-small'])}>
        <p className={styles.disclaimer} data-testid={blurIds.overlaySignInMsg}>Real estate boards require that you sign in to see listing details</p>
        <Button theme="secondary" expHomePadding={true} className={styles['overlay-button']} testId={blurIds.overlaySignInBtn} label="Sign In" />
      </div>
    );
  } else if (isTermsRequired) {
    return (
      <div className={buildClassName(styles.component, isSmallCard && styles['component-small'])}>
        <p className={styles.disclaimer} data-testid={blurIds.overlaySignInMsg}>Accept Terms of Use to see listing details</p>
        <Button theme="secondary" className={styles['overlay-button']} testId={blurIds.overlaySignInBtn} label="View" />
      </div>
    );
  } else {
    return null;
  }
}