import React from 'react';
import AdvancedSearch from 'components/advanced-search';
import Image from 'next/image';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';
import { useThemeContext } from 'contexts/theme';
import { buildClassName } from 'utils/build-class-name';
import { useIsMobile, useIsSmallMobile, useIsLargeDesktop } from 'hooks/use-size-class';
import { ThemeNames } from 'types/themes';

export default function HeaderSearch() {
  const { theme, themeName } = useThemeContext();
  const showBrushstrokes = theme && theme.decorations.brushstrokes;
  const isZoocasaTheme = themeName === ThemeNames.ZOOCASA;
  const isMobile = useIsMobile();
  const isSmallMobile = useIsSmallMobile();
  const isLargeDesktop = useIsLargeDesktop();

  return (
    <div className={buildClassName(styles.component, isMobile && styles['mobile-header'], isZoocasaTheme ? styles['default-header'] : styles['exp-header'])} data-testid={testIds.headerSearch}>
      {isLargeDesktop && theme.largeDesktopHeaderImage &&
        <Image
          src={theme.largeDesktopHeaderImage}
          fill
          alt='Header image'
          className={styles.headerImage}
          priority />
      }
      {!isMobile && !isLargeDesktop && theme.desktopHeaderImage &&
        <Image
          src={theme.desktopHeaderImage}
          fill
          alt='header image'
          className={styles.headerImage}
          priority />
      }
      {isMobile && theme.mobileHeaderImage &&
        <Image
          src={theme.mobileHeaderImage}
          fill
          objectPosition='center'
          alt='header image'
          className={styles['header-image']}
          priority />
      }
      {isSmallMobile && theme.smallMobileImage &&
        <Image
          src={theme.smallMobileImage}
          fill
          objectPosition='center'
          alt='header image'
          className={styles['header-image']}
          priority />
      }
      {showBrushstrokes && (
        <>
          <div className={`${styles['brush-stroke']} ${styles['brush-stroke-1']}`} data-testid={testIds.brushStroke1}>
            {!isMobile && <div className={`${styles['brush-stroke-1-content']}`}>
              <Image
                key="b-1"
                fill={true}
                src="/next/assets/images/brush-stroke-1.png"
                alt="Zoocasa brush stroke"
                priority={true}
              />
            </div>}
          </div>
          <div className={`${styles['brush-stroke']} ${styles['brush-stroke-2']}`} data-testid={testIds.brushStroke2}>
            <Image
              key="b-2"
              fill={true}
              src="/next/assets/images/brush-stroke-2.png"
              alt="Zoocasa brush stroke"
              priority={true}
            />
          </div>
          <div className={`${styles['brush-stroke']} ${styles['brush-stroke-3']}`} data-testid={testIds.brushStroke3}>
            <Image
              key="b-3"
              fill={true}
              src="/next/assets/images/brush-stroke-2.png"
              alt="Zoocasa brush stroke"
              priority={true}
            />
          </div>
        </>
      )}
      <div className={styles['search-wrapper']}>
        {!isZoocasaTheme ? <h1>Find Your Home</h1> : <h1>Find your home with us</h1>}
        <AdvancedSearch isFilterButtonHidden showsSearchOptions />
      </div>
    </div>
  );
}
