import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import styles from './video-reveal.module.scss';
import { buildClassName } from 'utils/build-class-name';
import { useIsMobile } from 'hooks/use-size-class';
import PlayButtonIcon from 'components/icon/play-button';
import VolumeIcon from 'components/icon/volume-icon';
import MuteIcon from 'components/icon/mute-icon';
import { useThemeContext } from 'contexts';

type VideoRevealStyles = React.CSSProperties & CustomCSSProperties;
interface CustomCSSProperties {
  '--container-width'?: string;
  '--container-height'?: string;
  '--arch-width'?: string;
  '--arch-height'?: string;
}
const TextContainer = ({ isMobile }:{isMobile: boolean}) => (
  <div className={isMobile? styles['mobile-text'] : styles['default-text']}>
    <h2>We&apos;re <span
      className={styles['header-highlight']} >Built</span> for This</h2>
    <p>We never settle, and we love it that way. Your success drives our passion for pushing the real estate industry beyond its limits.</p>
  </div>
);


const MuteButton = ({ onClick }: {onClick: () => void}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const iconFillColor = isHovered ? '#CECFD3' : 'white';

  return (
    <button className={styles['mute-button']}
      onClick={() => {
        setIsActive(!isActive);
        onClick();
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isActive ? <MuteIcon fill={iconFillColor} /> : <VolumeIcon fill={iconFillColor}/>}
    </button>
  );
};

const PlayButton = ({ onClick, isPlaying }: {onClick: () => void; isPlaying: boolean}) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
  }, [isPlaying]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <button id='playButton' className={styles['play-button']}
      onClick={ () =>onClick()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PlayButtonIcon foregroundFill={isHovered || isPlaying ? '#0C0F24': 'white'} backgroundFill={isHovered || isPlaying ? 'white' :'#0C0F24'}/>
    </button>
  );
};

const VideoReveal = () => {
  const videoSectionRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const pinContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const [isPlaying, setIsPlaying] = useState(false);
  const [locationHref, setLocationHref] = useState('');
  const { themeName } = useThemeContext();

  const [scrollPosition, setScrollPosition] = useState(0);

  const initialContainerWidth = 1440;
  const initialContainerHeight = 761;
  const initialArchWidth = 563;
  const initialArchHeight = 696.198;
  const toggleMute = () => {
    const video = videoRef.current;
    if (video) video.muted = !video.muted;
  };
  const initialClipPath = 'm242 51.6c-6.3 0.7-14.4 1.9-18 2.5-3.6 0.6-12.3 2.7-19.5 4.6-7.2 1.8-19.3 5.7-27 8.5-7.7 2.9-19.4 8-26 11.3-6.6 3.4-16.6 9-22.3 12.6-5.7 3.5-15.1 10-21 14.4-5.9 4.4-16.9 14.1-24.5 21.5-7.7 7.4-18.1 18.7-23.2 25-5.1 6.3-12 15.5-15.2 20.5-3.3 4.9-8.9 14.4-12.5 21-3.6 6.6-8.6 16.7-11.1 22.5-2.4 5.8-6.5 16.8-9 24.5-2.5 7.7-5.7 19.6-7.1 26.5-1.4 6.9-3.2 18.3-4.1 25.5-1.3 11.2-1.5 38.7-1.5 198.3v185.2h648v-626c-356.7 0.2-396.7 0.5-406 1.6z';

  useLayoutEffect(() => {
    const handleResize = () => {
      const containerWidth = videoSectionRef.current?.clientWidth || initialContainerWidth;
      const containerHeight = isMobile ? window.innerHeight -120 : containerWidth / (initialContainerWidth / initialContainerHeight);

      if (isMobile) {
        // Set up svg clip for mobile
        const scaleY = containerHeight / 2 / initialArchHeight; // Scale to half the container's height for the arch on mobile
        gsap.set('#clipPath', { scale: `${Math.min(containerWidth / initialArchWidth)}, ${scaleY}`, transform: `translate(0, ${containerHeight * .5}px)` });
      } else {
        const scale = Math.min(containerWidth / initialArchWidth, containerHeight / initialArchHeight);
        const translateX = containerWidth - initialArchWidth * scale - containerWidth * 0.05; // 5% margin

        gsap.set('#clipPath', {
          scale,
          transform: `translate3d(${translateX}px, 200px, 0)`,
        });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  /* eslint-disable react-hooks/exhaustive-deps */
  }, [isMobile]);

  useEffect(() => {
    const updateScrollPosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', updateScrollPosition);
    updateScrollPosition();
    return () => window.removeEventListener('scroll', updateScrollPosition);
  }, []);

  useLayoutEffect(() => {
    setLocationHref(window.location.href);

    videoRef.current?.pause();
    gsap.registerPlugin(ScrollTrigger);

    if (scrollPosition >= 1000) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: pinContainerRef.current,
          start: 'top 7%',
          end: 'bottom 100%',
          scrub: true,
          pin: true,
          invalidateOnRefresh: true,
          toggleActions: 'play none none reverse',
        },
      });

      tl.to('#clipPath', {
        scale: 5,
        x: isMobile ? '-600px' : '-300px',
        y: '-800px',
        ease: 'none',
        start: function () {
          if (!isMobile) {
            videoRef.current?.play();
          }
        },
      });

      if (isMobile) {
        tl.fromTo('#playButton', { opacity: 0 }, { opacity: 1, duration: 0.25 });
      }

      return () => {
        tl.revert();
      };
    }
  }, [scrollPosition]);

  return (
    <div
      ref={containerRef}
      className={buildClassName(styles.component, isMobile && styles['mobile-component'])}
      style={{
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '200vh',
        '--container-width': `${initialContainerWidth}px`,
        '--container-height': `${initialContainerHeight}px`,
        '--arch-width': `${initialArchWidth}px`,
        '--arch-height': `${initialArchHeight}px`,
      } as VideoRevealStyles}
    >
      <div
        ref={pinContainerRef}
        style={{
          backgroundColor: '#0C0F24',
          height: '100%',
        }}
      >
        <TextContainer isMobile={isMobile} />
        <div
          className={styles['svg-wrapper']}
          style={isMobile? {
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          } :
            {
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ref={videoSectionRef}
        >
          <svg width="0" height="0" preserveAspectRatio="meet" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
            <defs>
              <clipPath
                id="clipPath"
                style={{ transformOrigin: 'top left' }}
              >
                <path d={initialClipPath} />
              </clipPath>
            </defs>
          </svg>
          {isMobile && <PlayButton isPlaying={isPlaying} onClick={() => {
            setIsPlaying(!isPlaying);
            isPlaying? gsap.fromTo('#playButton', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: .25,
            }) :
              gsap.fromTo('#playButton', {
                opacity: 1,
              }, {
                opacity: 0,
                duration: .25,
              });
            !isPlaying ? videoRef.current?.play() : videoRef.current?.pause();
          }} />}
          <div
            className='videoWrapper'
            style={{
              objectFit: isMobile ? 'cover' : 'fill',
              clipPath: 'url(' + locationHref + '#clipPath)',
              WebkitClipPath: 'url(' + locationHref + '#clipPath)',
              transform: 'translateZ(0)',
              minHeight: isMobile ? '500px': 'unset',
              height: '100%',
              willChange: 'transform',
            }}
          >
            <video
              ref={videoRef}
              autoPlay
              width="100%"
              style={{
                objectFit: 'cover',
                transform: 'translateZ(0)',
                minHeight: isMobile? '500px': 'unset',
                height: '100%',
              }}
              muted
              loop={true}
            >
              <source src={themeName === 'exprealtyCA' ? '/assets/video-section1.mp4' : '/assets/video-section2.mp4'} type="video/mp4" />
            Your browser doesnt support HTML video.
            </video>
          </div>
          <MuteButton onClick={toggleMute}/>
        </div>
      </div>
    </div>
  );
};

export default VideoReveal;