import React from 'react';
import SqftText from 'components/sqft-text';
import styles from './style.module.scss';
import { listingCardIds } from 'constants/test-constants';

import type { SimilarListingCardData } from 'components/dynamic-page/listing-page/similar-listings-sections/similar_listing_card_data';

export interface ListingBedBathSqftLabelsData {
  bedrooms: number;
  bathrooms: number | null;
  avgSqft: number | null;
  sqftLabel: string;
  isCrea: boolean;
  isItsoVow: boolean;
  isPillar9Vow: boolean;
  isRebgvVow: boolean;
  providerId: number | null;
  agreementType: string | undefined;
}
interface Props {
  listing: ListingBedBathSqftLabelsData;
  listingToCompare?: ListingBedBathSqftLabelsData | SimilarListingCardData;
  isPrivate: boolean;
  requiresTerms: boolean;
  isWidget?: boolean;
}

export default function ListingBedBathSqftLabels({ listing, listingToCompare, isPrivate, requiresTerms }: Props) {
  let differenceInBedrooms = 0;
  let differenceInBathrooms = 0;
  let differenceInSqft = 0;

  if (listingToCompare) {
    differenceInBedrooms = listing.bedrooms - listingToCompare.bedrooms;
    differenceInBathrooms = Number(listing.bathrooms) - Number(listingToCompare.bathrooms);
    differenceInSqft = Number(listing.avgSqft) - Number(listingToCompare.avgSqft);
  }

  const getPlusMinusPrefix = (differenceInStat: number) => differenceInStat > 0 ? '+' : '';

  const getArrowClassName = (differenceInStat: number) => {
    if (differenceInStat > 0) {
      return 'arrow-up';
    } else if (differenceInStat < 0) {
      return 'arrow-down';
    }
    return '';
  };

  const getDifferenceLabel = (differenceInStat: number) => {
    if (differenceInStat !== 0) {
      return `(${getPlusMinusPrefix(differenceInStat)} ${differenceInStat})`;
    }
  };

  const renderLabels = () => {
    return (
      <div className={styles.row}>
        <div>
          <p className={styles['listing-detail']} data-testid={listingCardIds.listingBeds}>
            {<>{(isPrivate || requiresTerms) ? '-' : listing.bedrooms} <span>bd</span></>}
              
            {!(isPrivate || requiresTerms) && listingToCompare &&
                <>
                  {getDifferenceLabel(differenceInBedrooms)}
                  <span className={styles[getArrowClassName(differenceInBedrooms)]} />
                </>
            }
          </p>
          <p className={styles['listing-detail']} data-testid={listingCardIds.listingBathrooms}>
            {<>{(isPrivate || requiresTerms) ? '-' : listing.bathrooms} <span>ba</span></> }
            {!(isPrivate || requiresTerms) && listingToCompare &&
                <>
                  {getDifferenceLabel(differenceInBathrooms)}
                  <span className={styles[getArrowClassName(differenceInBathrooms)]} />
                </>
            }
          </p>
          <p className={styles['listing-detail']} data-testid={listingCardIds.listingSqft}>
            {<SqftText squareFootageLabel={(isPrivate || requiresTerms) ? '-' : listing.avgSqft?.toString() || 'N/A'} render={formattedSqft => <>{formattedSqft} <span>sqft</span></>} />}
            {!(isPrivate || requiresTerms) && listingToCompare && <span className={styles[getArrowClassName(differenceInSqft)]} />}
          </p>
        </div>
      </div>
    );
  };

  return renderLabels();
}
