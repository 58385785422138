import React from 'react';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';

interface Props {
  isSmallCard?: boolean;
  isImageReady: boolean;
  isTermsRequired?: boolean;
}

export default function ListingCardNewImageOverlay({ isSmallCard, isImageReady, isTermsRequired }: Props) {
  const message = isTermsRequired ? 'View and Accept Terms' : 'Sign in Required';

  return (
    <div className={buildClassName(styles.component, !isImageReady && styles.black)}>
      <div className={buildClassName(styles['new-overlay-text'], isSmallCard && styles['small-text'])}>
        <p>{message}</p>
      </div>
    </div>
  );
}