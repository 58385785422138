import React from 'react';

interface Props {
  fill?: string;
}

const MuteIcon = ({ fill }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="69" viewBox="0 0 74 69" fill="none">
      <circle cx="37" cy="35" r="20" fill={fill}/>
      <path d="M35 28.0703L30 32.0703H26V38.0703H30L35 42.0703V28.0703Z" stroke="#0C0F24" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M43.07 28C44.9447 29.8753 45.9979 32.4184 45.9979 35.07C45.9979 37.7216 44.9447 40.2647 43.07 42.14M39.54 31.53C40.4774 32.4676 41.004 33.7392 41.004 35.065C41.004 36.3908 40.4774 37.6624 39.54 38.6" stroke="#0C0F24" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  );
};

export default MuteIcon;
