import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { camelizeKeys } from '@zoocasa/node-kit/objects/transform-keys';

export default function useQueryParams(callback: (params: Record<string, unknown>) => void, ...queryParams: string[]) {
  const router = useRouter();
  useEffect(() => {
    const newParams: Record<string, unknown> = {};
    queryParams.forEach(queryParam => {
      if (router.query[queryParam]) {
        newParams[queryParam] = router.query[queryParam];
      }
    });
    if (Object.keys(newParams).length) {
      callback(camelizeKeys(newParams) as Record<string, unknown>);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);
}
