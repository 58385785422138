import React, { useState } from 'react';
import Link from 'components/link';
import BackArrowIcon from 'components/icon/back-arrow-icon';
import CloseIcon from 'components/icon/close-icon';
import Cookies from 'js-cookie';
import { DEFAULT_EXPIRE_DAYS, HIDE_NOTIFICATION_BANNER_COOKIE_NAME } from 'constants/cookies';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

interface Props {
  label: string;
  link: string;
  isHidden: boolean;
}

export enum NotificationBannerTestIds {
  COMPONENT = 'notification-banner-component',
  LABEL = 'notification-banner-label',
  LINK = 'notification-banner-link',
  CLOSE = 'notification-banner-close'
}

export default function NotificationBanner({ label, link, isHidden }: Props) {
  const [isBannerHidden, setIsBannerHidden] = useState(isHidden);
  
  const hideBanner = () => {
    setIsBannerHidden(true);
    Cookies.set(HIDE_NOTIFICATION_BANNER_COOKIE_NAME, JSON.stringify(true), { expires: DEFAULT_EXPIRE_DAYS });
  };

  if (isBannerHidden) {
    return null;
  }

  return (
    <div className={styles.component} data-testid={NotificationBannerTestIds.COMPONENT}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p data-testid={NotificationBannerTestIds.LABEL}>{label}</p>
          <Link href={link} className={styles.link} testId={NotificationBannerTestIds.LINK} onClick={hideBanner}><>Check Now <BackArrowIcon className={styles.arrow}/></></Link>
        </div>
        <CloseIcon className={styles.close} onClick={hideBanner} testId={testIds.notificationBannerClose} />
      </div>
    </div>
  );
}
