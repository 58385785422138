import React from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';
import HomePageExpCA from './themed-home-page-index/homepage-exp-ca';
import HomePageExpUS from './themed-home-page-index/homepage-exp-us';
import HomePageZoocasa from './themed-home-page-index/homepage-zoocasa';

import type { INamedContents } from 'pages';
import type { ListingCardData } from 'components/listing-card';

export interface HomePageProps {
  isCrawler: boolean;
  featuredHomesMlsNums: INamedContents['featuredHomesMlsNums'];
  isNotificationBannerHidden: boolean;
  availableHomeListings: ListingCardData[];
  availableHomeListingsMeta: number;
  availableHomeListingsLocation: string;
  isExpandedArea?: boolean;
}

export default function HomePage(props: HomePageProps) {
  const { themeName } = useThemeContext();

  switch (themeName) {
  case ThemeNames.EXP_REALTY_CA:
    return <HomePageExpCA {...props} />;
  case ThemeNames.EXP_REALTY_US:
    return <HomePageExpUS {...props} />;
  default:
    return <HomePageZoocasa {...props} />;
  }
}
