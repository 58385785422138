import React from 'react';

interface Props {
  foregroundFill?: string;
  backgroundFill?: string;
}

const PlayButtonIcon = ({ foregroundFill, backgroundFill }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 118 118" fill="none">
      <circle cx="59" cy="59" r="59" fill={foregroundFill}/>
      <path d="M72 58.5L53.25 69.3253V47.6747L72 58.5Z" fill={backgroundFill}/>
    </svg>
  );
};

export default PlayButtonIcon;
